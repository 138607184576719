<template>
  <div class="Toasters">
      <toaster v-for="toaster in toastersData" :id="toaster.id" :message="toaster.message" :icon="toaster.icon" :key="toaster.id" @remove="removeToaster" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import EventBus from '/src/script/modules/eventBus'
import Toaster from './Toaster.vue';

export default {
  components: { Toaster },
    name: "ToastersMessages",
    data() {
        return {
            toastersData: [],
        }
    },
    methods: {
        removeToaster(id) {
            this.toastersData = this.toastersData.filter(toaster => toaster.id != id)
        }
    },
    mounted() {
        EventBus.$on('toaster', (data) => {
            this.toastersData.push({ id: uuidv4(), ...data})
        })
    }
}
</script>

<style lang="scss" scoped>
    @use "/src/style/useful/variables.scss" as variables;
    .Toasters {
        position: fixed;
        top: variables.$size-header + 20px;
        right: 20px;
        z-index: 95;
        width: 300px;
        max-width: calc(100% - 40px);

        @media (variables.$mq-desktop) {
            top: variables.$size-header--desktop + 20px;
        }
    }
</style>