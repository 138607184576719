import Vue from 'vue'
import VueRouter from 'vue-router'
import webConfig from '/src/config/app'
import paths from '/src/script/config/paths'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: paths.home,
        name: 'Home',
        component: Home,
        meta: {
            title: `Accueil | ${webConfig.siteName}`
        }
    },
    {
        path: paths.about,
        name: 'About',
        component: () => import(/* webpackChunkName: "static" */ '../views/About.vue'),
        meta: {
            title: `À propos | ${webConfig.siteName}`,
            description: 'En savoir plus à propos de Lalie Landry.'
        }
    },
    {
        path: paths.books,
        name: 'Books',
        component: () => import(/* webpackChunkName: "static" */ '../views/Books.vue'),
        meta: {
            title: `Romans | ${webConfig.siteName}`,
            description: 'Découvrez tous les romans de Lalie Landry.'
        }
    },
    {
        path: paths.serie,
        name: 'Serie',
        component: () => import(/* webpackChunkName: "static" */ '../views/Serie.vue'),
        meta: {
            title: `Série | ${webConfig.siteName}`,
            description: 'Découvrez l\'univers de Lalie Landry.'
        }
    },
    {
        path: paths.novel,
        name: 'Novel',
        component: () => import(/* webpackChunkName: "static" */ '../views/Novel.vue'),
        meta: {
            title: `Roman | ${webConfig.siteName}`,
            description: ''
        }
    },
    {
        path: paths.contact,
        name: 'Contact',
        component: () => import(/* webpackChunkName: "static" */ '../views/Contact.vue'),
        meta: {
            title: `Contact | ${webConfig.siteName}`,
            description: 'Envie de rester en contact ?'
        }
    },
    {
        path: paths.events,
        name: 'Events',
        component: () => import(/* webpackChunkName: "static" */ '../views/Events.vue'),
        meta: {
            title: `Événements | ${webConfig.siteName}`,
            description: 'Découvrir les derniers événements.'
        }
    },
    {
        path: paths.event,
        name: 'Event',
        component: () => import(/* webpackChunkName: "static" */ '../views/Event.vue'),
        meta: {
            title: `Événements | ${webConfig.siteName}`,
            description: 'Découvrir les derniers événements.'
        }
    },
    {
        path: paths.cgu,
        name: 'CGU',
        component: () => import(/* webpackChunkName: "static" */ '../views/cgu.vue'),
        meta: {
            title: `CGU | ${webConfig.siteName}`,
            description: 'Conditions générales d\'utilisation'
        }
    },
    {
        path: paths.bonus,
        name: 'Bonus',
        component: () => import(/* webpackChunkName: "static" */ '../views/Bonus.vue'),
        meta: {
            title: `Bonus | ${webConfig.siteName}`,
            description: 'Accédez à un contenu spécial !'
        }
    },
    {
        path: paths.freebie,
        name: 'Freebie',
        component: () => import(/* webpackChunkName: "static" */ '../views/Freebie.vue'),
        meta: {
            title: `Freebie | ${webConfig.siteName}`,
            description: 'Une nouvelle gratuite !'
        }
    },
    {
        path: paths.newsletter,
        name: 'Newsletter',
        component: () => import(/* webpackChunkName: "static" */ '../views/Newsletter.vue'),
        meta: {
            title: `Newsletter | ${webConfig.siteName}`,
            description: 'Visualiser votre email en ligne.'
        }
    },
    {
        path: paths.unsubscribe,
        name: 'Unsubscribe',
        component: () => import(/* webpackChunkName: "static" */ '../views/Unsubscribe.vue'),
        meta: {
            title: `Unsubscribe | ${webConfig.siteName}`,
            description: ''
        }
    },
    {
        path: paths.admin,
        name: 'AdminBoard',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminBoard.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminLogin,
        name: 'AdminLogin',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminLogin.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminSliderContent,
        name: 'AdminSliderContent',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminSliderContent.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminEvents,
        name: 'AdminEvents',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminEvents.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminSeries,
        name: 'AdminSeries',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminSeries.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminNovels,
        name: 'AdminNovels',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminNovels.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminBonus,
        name: 'AdminBonus',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminBonus.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.adminNewsletter,
        name: 'AdminNewsletter',
        component: () => import(/* webpackChunkName: "administration" */ '../views/administration/AdminNewsletter.vue'),
        meta: {
            title: `Administration | ${webConfig.siteName}`,
        }
    },
    {
        path: paths.error404,
        name: '404',
        component: () => import(/* webpackChunkName: "error" */ '../views/Page404.vue')
    },
    {
        path: "*",
        name: '',
        component: () => import(/* webpackChunkName: "error" */ '../views/Page404.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || webConfig.siteName
    document.getElementsByTagName('meta')["description"].content = to.meta.description || 'Site officiel de Lalie Landry.'
    next()
})

export default router