<template>
  <div class="MobileMenu" :class="{ 'MobileMenu--open': menuOpened }">
    <div class="MobileMenu-icon disabledWhiteSpace" @click="openMenu"><icon name="menu" class="icon-32" /></div>
    <div class="MobileMenu-overlay" @click="closeMenu"></div>
    <div class="MobileMenu-contentContainer disabledWhiteSpace">
      <div class="MobileMenu-closeIcon" @click="closeMenu">
        <icon name="close" />
      </div>
      <span class="position-block text-heading text-heading--2 text-center mt-20 mb-32">Menu</span>
      <menu-item v-for="menuEntry in menuEntries" :url="menuEntry.url" :text="menuEntry.text" :key="menuEntry.text" @click="closeMenu" />
    </div>
  </div>
</template>

<script>
import Icon from '../../../components/Icon/Icon.vue'
import MenuItem from './MenuItem.vue'
export default {
  name: 'MobileMenu',
  components: { Icon, MenuItem },
  props: {
    menuEntries: Array
  },
  data() {
    return {
      menuOpened: false
    }
  },
  methods: {
    openMenu() {
      this.menuOpened = true
    },
    closeMenu() {
      this.menuOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @use "/src/style/useful/variables.scss" as variables;

  .MobileMenu {
    position: absolute;
    top: 50%;
    left: 10px;

    @media (variables.$mq-desktop) {
      display: none;
    }
  }

  .MobileMenu-icon {
    padding: 10px;
    transform: translateY(-50%);
  }

  .MobileMenu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background: variables.$color-transparent;
    transition: variables.$delay-basic;

    .MobileMenu--open & {
      background: rgba(variables.$color-black, .6);
      pointer-events: auto;
    }
  }

  .MobileMenu-contentContainer {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 300px;
    width: 100%;
    height: 100vh;
    background: variables.$color-black;
    border-right: 1px solid variables.$color-grey;
    padding: 40px 20px;
    transform: translateX(-100%);
    transition: variables.$delay-basic;

    .MobileMenu--open & {
      transform: translateX(0);
    }
  }

  .MobileMenu-closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
  }
</style>