<template>
  <a
    :id="id"
    class="PromotionalSlide position-backgroundCover"
    :class="{ 'PromotionalSlide--lightTheme': lightTheme }"
    :href="link"
    :style="`background-image: url(${
      $webConfig.slideImageBaseUrl + background
    });`"
    :target="link ? '_blank' : false"
  >
    <div class="PromotionalSlide-content page page--ySpace">
      <img
        class="PromotionalSlide-bookCover"
        :fetchpriority="imgPriority"
        :src="$webConfig.slideImageBaseUrl + img"
        alt=""
      />
      <div class="PromotionalSlide-textContainer">
        <h1 class="text-heading text-heading--2 text-left text-center mb-20">
          {{ title }}
        </h1>
        <div
          v-if="text"
          class="PromotionalSlide-text text-justify mb-20"
          v-html="textToHTML(text)"
        ></div>
        <div v-if="button" class="text-center">
          <btn
            class="
              PromotionalSlide-button
              Btn--darkPrimaryBorder
              text-important
            "
          >
            {{ button }}
          </btn>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { textToHTML } from "/src/script/modules/textHelper";
import Btn from "../../Btn/Btn.vue";

export default {
  components: { Btn },
  name: "PromotionalSlide",
  props: {
    id: { type: [String, Number], default: "" },
    link: { type: String, default: "#" },
    background: { type: String, default: "" },
    img: { type: String, default: "" },
    imgPriority: {type: String, default: "low"},
    title: { type: String, default: "" },
    text: { type: String, default: "" },
    button: { type: String, default: "" },
    lightTheme: { type: Boolean, default: false },
  },
  computed: {
    internalLink() {
      const linkElement = document.createElement('a');
      linkElement.href = this.link
      return linkElement.hostname === location.hostname
    },
  },
  methods: {
    textToHTML(text) {
      return textToHTML(text);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.PromotionalSlide {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  min-height: 644px;
  scroll-snap-align: center;
  color: variables.$color-white;
}
.PromotionalSlide--lightTheme {
  color: variables.$color-black;

  .PromotionalSlide-bookCover {
      box-shadow: 0 0 32px variables.$color-white;
  }
}

.PromotionalSlide-content {
  @media (variables.$mq-desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 644px;
  }
}

.PromotionalSlide-bookCover {
  display: block;
  width: calc(100% - 64px);
  max-width: 300px;
  height: auto;
  margin: 0 auto 32px auto;
  box-shadow: 0 0 32px variables.$color-black;

  @media (variables.$mq-desktop) {
    display: inline-block;
    flex: 0 0 350px;
    width: 350px;
    max-width: none;
    margin-left: 0;
    margin-right: 128px;
    object-fit: contain;
  }
}

.PromotionalSlide-textContainer {
  @media (variables.$mq-desktop) {
    flex: 0 0 40%;
    max-width: 425px;
  }
}

.PromotionalSlide-text {
  font-size: 1.7rem;
}

.PromotionalSlide-button {
  font-size: 2rem;
}
</style>