import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './script/store/appStore'
import webConfig from './config/app'
import { ApiHelper } from './script/modules/apiHelper'

Vue.config.productionTip = false

const axiosInstance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
})
const apiHelper = new ApiHelper(axiosInstance, store);

// global variable
Vue.prototype.$axios = axiosInstance
Vue.prototype.$api = apiHelper
Vue.prototype.$webConfig = webConfig

Vue.use(Vuex)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')