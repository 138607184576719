<template>
  <div class="NewsLetterInvitation">
    <div
      class="NewsLetterInvitation-container page page--ySpace text-colorWhite"
    >
      <div class="NewsLetterInvitation-background">
        <img :src="require(`/src/assets/image/paper.webp`)" alt="" />
      </div>
      <div class="NewsLetterInvitation-image">
        <img :src="require(`/src/assets/image/shortStoryCover.webp`)" alt="" />
      </div>
      <div class="NewsLetterInvitation-content">
        <h2 class="text-heading text-heading--2 mb-20">
          Une nouvelle gratuite dans l’univers d'<span class="text-italic">Une rivière de larmes et d’or</span> !
        </h2>
        <p class="mb-20">
          Envie de plonger dans le passé de l’antagoniste d’<span class="text-italic">Une rivière de larmes et d’or</span> ? Abonnez-vous à ma newsletter pour recevoir la nouvelle gratuite.
        </p>
        <form
          class="NewsLetterInvitation-form form"
          ref="form"
          action="#"
          method="post"
          @submit.prevent="submitForm"
        >
          <text-field
            v-model="userName"
            id="NewsLetterInvitationName"
            class="mb-20"
            name="userName"
            label="Prénom :"
            placeholder="Entrez votre prénom..."
            :maxlength="100"
            :required="true"
          />
          <text-field
            v-model="userMail"
            id="NewsLetterInvitationEmail"
            class="mb-20"
            type="email"
            name="userMail"
            label="Email :"
            placeholder="Entrez votre adresse email..."
            :maxlength="100"
            :required="true"
          />
          <checkbox-field
            v-model="userConsent"
            id="userConsent"
            class="mb-20"
            name="userConsent"
            label="En cochant cette case, vous acceptez que votre adresse email soit mémorisée et utilisée dans le but de vous envoyer des lettres d’information."
            :required="true"
          />
          <btn
            class="Btn--darkPrimary"
            type="submit"
            value="submit"
            :disabled="!formIsValide"
          >
            S'inscrire
          </btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "/src/script/modules/eventBus";
import apiPath from "/src/script/config/apiPath";

import Btn from "../Btn/Btn.vue";
import CheckboxField from "../Form/CheckboxField.vue";
import TextField from "../Form/TextField.vue";

export default {
  name: "NewsLetterInvitation",
  components: { TextField, Btn, CheckboxField },
  data() {
    return {
      userName: "",
      userMail: "",
      userConsent: false,
    };
  },
  computed: {
    formIsValide() {
      return this.userName && this.userMail && this.userConsent;
    },
  },
  methods: {
    async submitForm() {
      const data = {
        name: this.userName,
        email: this.userMail,
        consent: this.userConsent,
      };

      const response = await this.$api.request(
        this.$webConfig.apiBaseUrl + apiPath.newsletterSubscribe,
        data
      );

      if (response?.data?.code === 200) {
        EventBus.$emit("toaster", {
          message: "Votre inscription a bien été enregistrée.",
        });
        this.$emit('validated');
        this.userName = "";
        this.userMail = "";
        this.userConsent = false;
        return;
      }

      if (response?.data?.code === 304) {
        EventBus.$emit("toaster", {
          message: "Votre adresse email est déjà enregistrée.",
        });
        return;
      }

      EventBus.$emit("toaster", {
        message:
          "Une erreur interne est survenue. Merci de réessayer plus tard...",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.NewsLetterInvitation {
  position: relative;
  background: variables.$color-black;
  overflow: hidden;

  & ::v-deep .form input[type="checkbox"] + label {
    color: variables.$color-grey;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.NewsLetterInvitation-container {
  @media (variables.$mq-desktop) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.NewsLetterInvitation-content {
  position: relative;
  z-index: 2;
  padding-right: 20px;
}

.NewsLetterInvitation-content,
.NewsLetterInvitation-image {
  @media (variables.$mq-desktop) {
    width: 45%;
  }
}

.NewsLetterInvitation-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 350px;

  @media (variables.$mq-from-tablet) {
    top: 0;
    right: 45%;
    height: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: 200px;
    background: linear-gradient(
      to bottom,
      variables.$color-black,
      variables.$color-transparent
    );

    @media (variables.$mq-from-tablet) {
      bottom: 0;
      height: auto;
      background: linear-gradient(
        250deg,
        variables.$color-black,
        variables.$color-black 45%,
        variables.$color-transparent
      );
    }
  }

  & img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
  }
}

.NewsLetterInvitation-image {
  position: relative;
  z-index: 2;
  height: auto;
  text-align: center;
  margin-bottom: 32px;

  & img {
    width: 280px;
    margin-bottom: 0;
  }
}
</style>