<template>
    <div class="Layout Layout--default">
        <div class="Layout-content">
            <slot></slot>
        </div>
        <toasters />
        <redirect />
    </div>
</template>

<script>
import Redirect from '../components/Redirect/Redirect.vue'
import Toasters from '../components/Toasters/Toasters.vue'

export default {
    name: 'DefaultLayout',
    components: { Redirect, Toasters }
}
</script>

<style>

</style>