function textToHTML(text) {
    if (!text) {
        return "";
    }

    const lineBreak = "\n";
    const paragraphe = text.split(`${lineBreak}${lineBreak}`);
    const htmlContent = paragraphe
        .map(
            (paragraphe) =>
                `<p>${paragraphe
                    .split(`${lineBreak}`)
                    .map((line) => `<span class="position-block">${line}</span>`)
                    .join("")}</p>`
        )
        .join("");

    return htmlContent;
}

function slugify(text) {
    return text
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

function formatDateTime(dateTimeString, options = { dateStyle: "medium", timeStyle: "medium" }) {
    if (!dateTimeString) {
        return '';
    }

    return dateTimeString.toLocaleString("fr", options)
}

export { textToHTML, slugify, formatDateTime }