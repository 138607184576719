const api = 'https://api.lalie-landry.com'

const config = {
  siteName: 'Lalie Landry',
  apiBaseUrl: api,
  slideImageBaseUrl: api + '/upload/slides/' ,
  serieImageBaseUrl: api + '/upload/series/',
  novelImageBaseUrl: api + '/upload/novels/',
  eventImageBaseUrl: api + '/upload/events/',
  contactEmail: 'lalie@lalie-landry.com',
  contactMail: 'Au-delà du miroir éditions,<br/>110 avenue de Clichy,<br/> 75017 Paris,<br/> à l\'attention de Lalie Landry',
  bddTrueValue: 1,
  bddFalseValue: 0,
}

export default config