<template>
  <svg
    :class="`icon icon--${name}`"
    @click="$emit('click')"
  >
    <use
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :xlink:href="`${require('/public/sprite.svg')}#${name}`"
      :href="`${require('/public/sprite.svg')}#${name}`"
    ></use>
  </svg>
</template>

<script>
  /* eslint-disable */
  export default {
    name: 'Icon',
    props: {
      name: String,
    },
  }
</script>