import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'my-app',
  storage: window.localStorage
})

// Create a new store instance.
const store = new Vuex.Store({
  state: {
    session: {
      token: '',
    }
  },
  getters: {
    hasToken(state) {
      return Boolean(state.session.token)
    }
  },
  mutations: {
    setToken(state, payload) {
      state.session.token = payload.token
    }
  },
  plugins: [vuexPersist.plugin]
})

export default store