<template>
  <div class="NetworksBanner">
    <div
      class="
        NetworksBanner-content
        page page--ySpace
        position-flexContainer
        position-flexContainer--center
        position-flexContainer--wrap
        position-flexContainer--vCenter
      "
    >
      <h3 class="NetworksBanner-text text-heading text-heading--2">
        Suivez-moi sur les réseaux :
      </h3>
      <a :href="network.instagram" target="_blank" aria-label="Instagram"><icon name="instagram" /></a>
      <a :href="network.tiktok" target="_blank" aria-label="tiktok"><icon name="tiktok" /></a>
      <a :href="network.facebook" target="_blank" aria-label="Facebook"><icon name="facebook" /></a>
      <a :href="network.threads" target="_blank" aria-label="Threads"><icon name="threads" /></a>
    </div>
    <particles-generator />
  </div>
</template>

<script>
import Icon from "../Icon/Icon.vue";
import ParticlesGenerator from "../ParticlesGenerator/ParticlesGenerator.vue";
import webConfig from "/src/script/config/webConfig";

export default {
  components: { Icon, ParticlesGenerator },
  name: "NetworksBanner",
  data() {
    return {
      network: webConfig.network
    }
  },
};
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.NetworksBanner {
  position: relative;
  background: variables.$color-grey;
  color: variables.$color-dark-grey;

  & ::v-deep .icon {
    width: 80px;
    height: 80px;
  }

  & ::v-deep .ParticlesGenerator {
    z-index: 1;

    .ParticlesGenerator-particle {
      background: variables.$color-dark-grey;

      &::after {
        box-shadow: 0px 0px 5px variables.$color-black;
      }
    }
  }
}

.NetworksBanner-content {
    position: relative;
    z-index: 2;
}

.NetworksBanner-text {
  flex: 0 0 100%;
  text-align: center;
}
</style>