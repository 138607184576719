<template>
  <div class="StaticLoader">
      <div class="StaticLoader-border"></div>
      <icon name="logo" />
  </div>
</template>

<script>
import Icon from '../Icon/Icon.vue'
export default {
  components: { Icon },
    name: "StaticLoader"
}
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.StaticLoader {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-left: 12px;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.StaticLoader-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 1px solid currentColor;
    border-radius: 50%;
    border-color: currentColor variables.$color-transparent currentColor variables.$color-transparent;
    animation: rotate 1.2s linear infinite;
}
</style>