<template>
  <div class="Redirect">
  </div>
</template>

<script>
import EventBus from '/src/script/modules/eventBus'

export default {
    name: "RedirectLink",
    mounted() {
        EventBus.$on('redirect', (data) => {
            this.$router.push({ path: data.path })
        })
    }
}
</script>