export class ApiHelper {
  constructor(axios, store) {
    this.axios = axios
    this.store = store
  }

  request(url, data = undefined) {
    const token = this.store.state.session.token
    const payload = data ? { token, ...data } : { token }
    return this.axios.post(url, payload)
      .then((response) => {
        return response
      })
  }

  requestFormData(url, formData, postData = null) {
    const headers =  {
      'Content-Type': 'multipart/form-data'
    }
    const token = this.store.state.session.token

    if (postData) {
      formData.append("post", new Blob([JSON.stringify({ ...postData, token })]), "data");
    } else {
      formData.append("post", new Blob([JSON.stringify({ token })]), "data");
    }

    return this.axios.post(url, formData, { headers })
      .then((response) => {
        return response
      })
  }
}
