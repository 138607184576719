const apiPaths = {
    contact: '/contact',

    login: '/login',
    checkConnexion: '/checkConnexion',

    slides: '/slides',
    getSlides: '/getSlides',
    newSlide: '/newSlide',
    editSlide: '/editSlide',
    deleteSlide: '/deleteSlide',

    event: '/event/:slug',
    getEvents: '/getEvents',
    getEventsList: '/getEventsList',
    newEvent: '/newEvent',
    editEvent: '/editEvent',
    deleteEvent: '/deleteEvent',

    bonus: '/bonus/:slug',
    getBonus: '/getBonus',
    newBonus: '/newBonus',
    editBonus: '/editBonus',
    deleteBonus: '/deleteBonus',

    serie: '/serie/:slug',
    getSeries: '/getSeries',
    newSerie: '/newSerie',
    editSerie: '/editSerie',
    deleteSerie: '/deleteSerie',

    novel: '/novel/:slug',
    getNovels: '/getNovels',
    newNovel: '/newNovel',
    editNovel: '/editNovel',
    deleteNovel: '/deleteNovel',

    newsletterSubscribe: '/newsletterSubscribe',
    unsubscribe: '/unsubscribe',
    onlineNewsletter: '/onlineNewsletter',
    getNewslettersInformation: '/getNewslettersInformation',
    getNewslettersCampaign: '/getNewslettersCampaign',
    newNewsletterCampaign: '/newNewsletterCampaign',
    editNewsletterCampaign: '/editNewsletterCampaign',
    deleteNewsletterCampaign: '/deleteNewsletterCampaign',
    sendNewsletterCampaign: '/sendNewsletterCampaign',
}

export default apiPaths;