<template>
  <div class="DesktopMenu">
    <div class="DesktopMenu-content">
      <menu-item v-for="menuEntry in menuEntries" :url="menuEntry.url" :text="menuEntry.text" :key="menuEntry.text" />
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
export default {
  components: { MenuItem },
    name: 'DesktopMenu',
    props: {
      menuEntries: Array,
    }
}
</script>

<style lang="scss" scoped>
  @use "/src/style/useful/variables.scss" as variables;

  .DesktopMenu {
    display: none;

    @media (variables.$mq-desktop) {
      display: inline-block;
      flex: 0 1 800px;
      margin-left: 64px;
    }
  }

  .DesktopMenu-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>