<template>
    <div class="ParticlesGenerator">
        <div
            v-for="particle in particles"
            class="ParticlesGenerator-particle"
            :style="`left: ${particle.x}; width: ${particle.size}; height: ${particle.size}; animation-duration: ${particle.speed};`"
            :data-animation="particle.animation"
            :key="particle.id"
        ></div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'ParticlesGenerator',
  data() {
      return {
          particles: [],
          generator: undefined,
          intervalTime: window.innerWidth >= 1024 ? 600 : 1200,
          nbParticles: window.innerWidth >= 1024 ? 4 : 2
      }
  },
  methods: {
      startGenerate() {
          this.generator = setInterval(() => {
              this.clearParticle()
              const newParticles = Array.from({length: this.nbParticles}, () => this.newParticle());
              const creationTime = this.getRandom(0, 1000)
              setTimeout(() => {
                  this.particles.push(...newParticles)
              }, creationTime)
          }, this.intervalTime)
      },
      newParticle() {
          const born = new Date()
          const life = this.getRandom(300, 1000)
          const endLife = life + this.getRandom(100, 500)
          return {
              id: uuidv4(),
              born: born,
              endLife: new Date(new Date().getTime() + endLife + 1000),
              life: life,
              size: `${ this.getRandom(0, 3) }px`,
              speed: `${ endLife + this.getRandom(0, 300) }ms`,
              x: `${ this.getRandom(0, 100) }%`,
              animation: `particle${ this.getRandom(1, 4) }`,
          }
      },
      clearParticle() {
          const now = new Date()
          this.particles = this.particles.filter(particle => now < particle.endLife)
      },
      getRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
      }
  },
  mounted() {
      // TODO : check if need
      // if (window.innerWidth >= 1024) {
          this.startGenerate()
      // }
  }
}
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.ParticlesGenerator {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
    pointer-events: none;
}

.ParticlesGenerator-particle {
    position: absolute;
    display: inline-block;
    bottom: -5px;
    border-radius: 50%;
    opacity: 1;
    transition: variables.$delay-long;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    background: variables.$color-grey;

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
        box-shadow: 0px 0px 5px variables.$color-dark-grey;
    }

    &[data-animation="particle1"] { animation-name: particle1; }
    &[data-animation="particle2"] { animation-name: particle2; }
    &[data-animation="particle3"] { animation-name: particle3; }
    &[data-animation="particle4"] { animation-name: particle4; }
}

@keyframes particle1 {
    0% {
        opacity: 1;
        transform: scale(1) translate(0, 0);
    }
    25% {
        transform: scale(1.5) translate(+25px, -25px);
    }
    50% {
        transform: scale(1.5) translate(+35px, -50px);
    }
    75% {
        opacity: 1;
        transform: scale(1) translate(+35px, -90px);
    }
    100% {
        opacity: 0;
        transform: scale(1) translate(+25px, -100px);
    }
}

@keyframes particle2 {
    0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
    25% {
        transform: translate(-10px, -25px) scale(1);
    }
    50% {
        transform: translate(+5px, -50px) scale(1.5);
    }
    75% {
        opacity: 1;
        transform: translate(+25px, -75px) scale(1.5);
    }
    100% {
        opacity: 0;
        transform: translate(+35px, -120px) scale(1);
    }
}

@keyframes particle3 {
    0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
    25% {
        transform: translate(-5px, -50px) scale(1.5);
    }
    50% {
        transform: translate(+5px, -65px) scale(1);
    }
    75% {
        opacity: 1;
        transform: translate(-10px, -75px) scale(1.5);
    }
    100% {
        opacity: 0;
        transform: translate(+5px, -110px) scale(1);
    }
}

@keyframes particle4 {
    0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
    25% {
        transform: translate(-10px, -20px) scale(1.5);
    }
    50% {
        transform: translate(+25px, -45px) scale(1);
    }
    75% {
        opacity: 1;
        transform: translate(+35px, -65px) scale(1.5);
    }
    100% {
        opacity: 0;
        transform: translate(+55px, -80px) scale(1.5);
    }
}
</style>