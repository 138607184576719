<template>
  <div
    class="Toaster position-flexContainer position-flexContainer--vCenter"
    :class="{ 'Toaster--enter': show, 'Toaster--leave': !show }"
  >
    <div
      class="Toaster-text position-flexContainer position-flexContainer--vCenter"
    >
      <icon v-if="icon" :name="icon" />
      <div class="position-flexItem--maximized">
        <span>{{ message }}</span>
      </div>
    </div>
    <icon name="close" @click="close" />
  </div>
</template>

<script>
import Icon from "../Icon/Icon.vue";

export default {
  components: { Icon },
  name: "ToasterMessage",
  props: {
    id: String,
    message: String,
    icon: { type: String, default: "" },
  },
  data() {
    return {
      timeToDisplay: 5000,
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.open();
    }, 100);

    setTimeout(() => {
      this.close();
    }, this.timeToDisplay - 500);

    setTimeout(() => {
      this.$emit("remove", this.id);
    }, this.timeToDisplay);
  },
};
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;
.Toaster {
  display: flex;
  width: 100%;
  padding: 20px;
  background: variables.$color-primary;
  color: variables.$color-white;
  opacity: 0;
  transform: translateX(120%);
  transition: 0.5s;

  &.Toaster--enter {
    opacity: 1;
    transform: translateX(0);
  }
  &.Toaster--leave {
    opacity: 0;
  }
}

.Toaster-text {
  flex: 100% 1 1;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}
</style>