<template>
    <router-link class="MenuItem" :to="url" @click.native="clickAction">
        <span class="MenuItem-text text-heading text-heading--3 p-20">
            {{ text }}
            <icon name="underline" />
        </span>
    </router-link>
</template>

<script>
import Icon from '../../../components/Icon/Icon.vue'
export default {
  components: { Icon },
    name: 'MenuItem',
    props: {
        url: [String, Object],
        text: String
    },
    methods: {
        clickAction() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
    @use "/src/style/useful/variables.scss" as variables;

    .MenuItem-text {
        position: relative;
        text-align: center;

        &:hover {
            font-weight: 700;
            color: variables.$color-primary;
            transition: variables.$delay-basic;
        }

        ::v-deep .icon--underline {
            position: absolute;
            left: 50%;
            bottom: -16px;
            width: 80px;
            height: 50px;
            opacity: 0;
            transform: translateX(-50%);
            transition: variables.$delay-basic;

            .router-link-exact-active & {
                opacity: 1;
            }
        }

        .router-link-exact-active & {
            font-weight: 700;
            color: variables.$color-primary;
        }
    }
</style>