<template>
  <div
    class="PromotionalSlider"
    :class="{
      'text-colorWhite': !slides[activeSlideIndex]?.lightTheme,
    }"
  >
    <transition name="fade" mode="out-in">
      <icon
        v-if="!slides.length"
        class="PromotionalSlider-loader"
        name="icon"
      />
      <div
        v-else
        class="PromotionalSlider-slides position-flexContainer"
        :class="{ 'PromotionalSlider-slides--animated': showAnimation }"
        ref="slides"
      >
        <promotional-slide
          v-for="(slide, index) in slides"
          :id="slide.id"
          :link="slide.link"
          :background="slide.background"
          :img="slide.img"
          :imgPriority="index === 0 ? 'hight': 'low'"
          :title="slide.title"
          :text="slide.text"
          :button="slide.buttonText"
          :lightTheme="slide.lightTheme"
          :style="`background-image: url(${
            $webConfig.slideImageBaseUrl + slide.background
          });`"
          :target="slide.link ? '_blank' : false"
          :key="slide.id"
        />
        <div
          v-if="slides.length > 1"
          class="PromotionalSlider-previousSlideBtn icon-pointer"
          @click="clickPreviousSlide"
        >
          <icon name="chevron" />
        </div>
        <div
          v-if="slides.length > 1"
          class="PromotionalSlider-nextSlideBtn icon-pointer"
          @click="clickNextSlide"
        >
          <icon name="chevron" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import throttle from "lodash/throttle";
import Icon from "../Icon/Icon.vue";
import PromotionalSlide from "./Components/PromotionalSlide.vue";
export default {
  components: { Icon, PromotionalSlide },
  name: "PromotionalSlider",
  props: {
    slides: { type: Array, default: () => [] },
  },
  data() {
    return {
      activeSlideIndex: 0,
      animationStarted: false,
      manualNavigation: false,
      showAnimation: false,
      animationDelay: 5000,
    };
  },
  methods: {
    clickNextSlide() {
      this.manualNavigation = true;
      this.nextSlide();
    },
    clickPreviousSlide() {
      this.manualNavigation = true;
      this.previousSlide();
    },
    nextSlide() {
      this.activeSlideIndex =
        this.activeSlideIndex < this.slides.length - 1
          ? this.activeSlideIndex + 1
          : 0;
    },
    previousSlide() {
      this.activeSlideIndex =
        this.activeSlideIndex === 0
          ? this.slides.length - 1
          : this.activeSlideIndex - 1;
    },
    startAnimation() {
      if (!this.animationStarted && this.slides.length > 1) {
        setInterval(() => {
          if (!this.manualNavigation) {
            this.nextSlide();
          }
        }, this.animationDelay);

        const intervalAddScrollEvent = setInterval(() => {
          if (this.$refs.slides) {
            this.$refs.slides.addEventListener(
              "scroll",
              throttle(() => {
                if (!this.showAnimation && !this.manualNavigation) {
                  this.manualNavigation = true;
                }
              }, 700)
            );
            clearInterval(intervalAddScrollEvent);
          }
        }, 200);

        this.animationStarted = true;
      }
    },
  },
  watch: {
    activeSlideIndex(newValue) {
      this.showAnimation = true;
      const slideSize = document.body.clientWidth;
      const scrolValue = slideSize * newValue;
      setTimeout(() => {
        this.$refs.slides.scroll({
          top: 0,
          left: scrolValue,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.showAnimation = false;
        }, 1000);
      }, 300);
    },
    slides() {
      this.startAnimation();
    },
  },
  mounted() {
    this.startAnimation();
  },
};
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.PromotionalSlider {
  position: relative;
  min-height: 644px;
  background: variables.$color-dark-grey;

  @media (variables.$mq-desktop) {
    min-height: 672.44px; // TODO : default 644px
  }
}

.PromotionalSlider-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  padding: 30px;
  border: 2px solid variables.$color-white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: loadPulse variables.$delay-long infinite linear;
}

.PromotionalSlider-slides {
  width: 100%;
  overflow: auto;

  &:not(.PromotionalSlider-slides--animated) {
    scroll-snap-type: x mandatory;
  }

  @media (variables.$mq-desktop) {
    overflow: hidden;
  }
}

.PromotionalSlider-nextSlideBtn,
.PromotionalSlider-previousSlideBtn {
  display: none;

  @media (variables.$mq-desktop) {
    display: inline-block;
    position: absolute;
    top: 50%;
    transition: variables.$delay-basic;
    transition-delay: .3s;
  }

  & ::v-deep .icon {
    width: 60px;
    height: 100%;
    padding: 10px;
    opacity: 0.6;
  }
}
.PromotionalSlider-nextSlideBtn {
  right: 5%;
  transform: translate(50%, -50%);

  & ::v-deep .icon {
    transform: scaleX(-1);
  }
}
.PromotionalSlider-previousSlideBtn {
  left: 5%;
  transform: translate(-50%, -50%);
}
</style>