<template>
  <footer class="LayoutFooter">
    <div class="page">
      <div class="LayoutFooter-column">
        <icon class="LayoutFooter-logo mb-6" name="icon" />
        <div v-for="menuItem in menuItems" :key="`footer-link-${menuItem.text}`">
          <router-link class="text-title text-heading--4 position-inlineBlock pt-8 pb-8" :to='menuItem.url'>
            {{ menuItem.text }}
          </router-link>
        </div>
        <a class="text-title text-heading--4 position-inlineBlock pt-8 pb-8" href="https://audeladumiroireditions.com/" target="_blank">ADDM</a>
      </div>
      <span class="position-block text-center text-colorGrey mt-32">
        Copyright © 2023 - {{ year }} Lalie Landry. Tous droits réservés.
      </span>
      <span class="position-block text-center text-colorGrey mt-12"><a href="https://bit.ly/m/JordanDey" target="_blank">Réalisé par Jordan Dey.</a> — <router-link :to='paths.cgu'>Mentions légales et politique de confidentialité.</router-link></span>
    </div>
  </footer>
</template>

<script>
import Icon from '../../components/Icon/Icon.vue'
import menuConfig from '/src/script/config/menuConfig'
import paths from '/src/script/config/paths'

export default {
  components: { Icon },
    name: 'LayoutFooter',
    data() {
      return {
        year: new Date().getFullYear(),
        menuItems: menuConfig,
        paths,
      }
    }
}
</script>

<style lang="scss" scoped>
@use "/src/style/useful/variables.scss" as variables;

.LayoutFooter {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  background: variables.$color-black;
  color: variables.$color-light-grey;
  overflow: hidden;
}

.LayoutFooter-logo {
  width: 64px;
  height: 64px;
  margin-left: -18px;
}
</style>