const webConfig = {
    network: {
        facebook: 'https://www.facebook.com/people/Lalie-Landry/100092491664605/',
        instagram: 'https://www.instagram.com/lalie_landry/',
        threads: 'https://www.threads.net/@lalie_landry',
        tiktok: 'https://www.tiktok.com/@lalie_landry_autrice',
    },
    linkTypes: {
        addm: { id: "addm", name: "ADDM", order: 1 },
        amazon: { id: "amazon", name: "Amazon", order: 2 },
        fnac: { id: "fnac", name: "Fnac", order: 3 },
        kobo: { id: "kobo", name: "Kobo", order: 4 },
        apple: { id: "apple", name: "Apple", order: 5 },
        bookelis: { id: "bookelis", name: "Bookelis", order: 6 },
        pdf: { id: "pdf", name: "Gratuit PDF", order: 7 },
        epub: { id: "epub", name: "Gratuit EPUB", order: 8 }
    }
}

export default webConfig;