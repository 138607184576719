<template>
  <div class="TextField">
    <label
      v-if="label"
      class="text-colorPrimary text-bold"
      :class="{ 'position-none': hiddeLabel }"
      :for="id"
    >
      {{ label }}
    </label>
    <input
      v-if="type !== 'textarea'"
      v-model="modelValue"
      :id="id"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :required="required"
      :disabled="disabled"
    />
    <textarea
      v-else
      v-model="modelValue"
      :id="id"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "TextField",
  model: {
    modelValue: "value",
    event: "change",
  },
  props: {
    id: String,
    label: String,
    hiddeLabel: { type: Boolean, default: false },
    name: { type: String, default: "" },
    type: { type: String, default: "text" },
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    maxlength: { type: Number, default: undefined }
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  watch: {
    value: function (newValue) {
      this.modelValue = newValue;
    },
    modelValue: function (newValue) {
      this.$emit("change", newValue);
    },
  },
};
</script>