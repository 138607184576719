<template>
  <button class="Btn" :type="type" :value="value" :disabled='disabled' @click="$emit('click')"><slot></slot><static-loader v-if="loader" /></button>
</template>

<script>
import StaticLoader from '../StaticLoader/StaticLoader.vue'
export default {
  components: { StaticLoader },
    name: "AppBtn",
    props: {
        disabled: Boolean,
        type: String,
        value: String,
        loader: { type: Boolean, default: false }
    }
}
</script>