<template>
  <div class="Home">
    <promotional-slider :slides="formatedSlides" />
    <news-letter-invitation />
    <networks-banner />
  </div>
</template>

<script>
import NetworksBanner from '../components/NetworksBanner/NetworksBanner.vue';
import NewsLetterInvitation from '../components/NewsLetterInvitation/NewsLetterInvitation.vue';
import PromotionalSlider from '../components/PromotionalSlider/PromotionalSlider.vue';
import apiPath from "/src/script/config/apiPath";

export default {
  components: { PromotionalSlider, NewsLetterInvitation, NetworksBanner },
  name: "HomePage",
  data() {
    return {
      slides: []
    }
  },
  computed: {
    formatedSlides() {
      return this.slides.map((slide) => {
        return { ...slide, lightTheme: slide.lightTheme === this.$webConfig.bddTrueValue }
      })
    }
  },
  async beforeMount() {
    const response = await this.$api.request(
      this.$webConfig.apiBaseUrl + apiPath.slides
    );

    this.slides = response.data.slides;
  }
}
</script>