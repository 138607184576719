<template>
  <div id="app">
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view v-bind:layout.sync="pageLayout" />
      </transition>
    </component>
  </div>
</template>

<script>
import DefaultLayout from './layout/DefaultLayout.vue'
import EmptyLayout from './layout/EmptyLayout.vue'

export default {
  name: "App",
  components: { DefaultLayout, EmptyLayout },
  data() {
    return {
      pageLayout: "",
    }
  },
  computed: {
    layout() { return this.pageLayout || 'default-layout' }
  },
  watch:{
      $route () {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })

          this.pageLayout = "";
      }
  }
}
</script>

<style lang="scss">
  @use "src/style/main.scss";
</style>
