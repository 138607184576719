const paths = {
    home : '/',
    about: '/a-propos',
    books: '/romans',
    serie: '/serie/:slug',
    novel: '/roman/:slug',
    contact: '/contact',
    events: '/evenements',
    event: '/evenement/:slug',
    cgu: '/cgu',
    
    bonus: '/bonus/:slug',
    freebie: '/freebie',

    newsletter: '/newsletter/:slug',
    unsubscribe: '/unsubscribe/:slug',
    
    admin: '/administration',
    adminLogin: '/administration-login',
    adminSliderContent: '/administration-sliderContent',
    adminEvents: '/administration-events',
    adminSeries: '/administration-series',
    adminNovels: '/administration-novels',
    adminBonus: '/administration-bonus',
    adminNewsletter: '/administration-newsletter',

    error404: '/erreur-404'
}

export default paths;