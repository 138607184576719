<template>
  <header class="LayoutHeader pt-12 pb-12" :class="{ 'LayoutHeader--collaps' : collaps }">
    <div class="LayoutHeader-content page">
      <mobile-menu :menuEntries="menuEntries" />
      <router-link to="/" class="disabledWhiteSpace" aria-label="Lalie Landry">
        <icon class="LayoutHeader-logo" name="logo" />
      </router-link>
      <desktop-menu :menuEntries="menuEntries" />
    </div>
    <particles-generator />
  </header>
</template>

<script>
import Icon from '../../components/Icon/Icon.vue'
import ParticlesGenerator from '../../components/ParticlesGenerator/ParticlesGenerator.vue'
import DesktopMenu from './LayoutMenu/DesktopMenu.vue'
import MobileMenu from './LayoutMenu/MobileMenu.vue'
import menuConfig from '/src/script/config/menuConfig'
import throttle from 'lodash/throttle'

export default {
  name: 'LayoutHeader',
  components: { DesktopMenu, MobileMenu, Icon, ParticlesGenerator },
  data() {
    return {
      menuEntries: menuConfig,
      collaps: false
    }
  },
  methods: {
    updateCollaps() {
      this.collaps = window.scrollY > 0
    }
  },
  mounted() {
    this.updateCollaps()

    window.addEventListener('scroll', throttle(this.updateCollaps, 100))
  }
}
</script>

<style lang="scss" scoped>
  @use "/src/style/useful/variables.scss" as variables;

  .LayoutHeader {
    position: sticky;
    z-index: 50;
    top: 0;
    background: rgba(variables.$color-black, 0.9);
    backdrop-filter: blur(20px);
    color: variables.$color-white;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: rgba(variables.$color-black, 0.9);
      backdrop-filter: blur(20px);
      transform: translateY(-100%);
    }
  }

  .LayoutHeader-content {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    @media (variables.$mq-desktop) {
      justify-content: left;
    }
  }

  .LayoutHeader-logo {
    width: 200px;
    height: 83px;
    transition: variables.$delay-short;

    @media (variables.$mq-desktop) {
      width: 250px;
      height: 104px;
    }
  }

  .LayoutHeader--collaps {
    .LayoutHeader-logo {
      width: 140px;
      height: 58px;

      @media (variables.$mq-desktop) {
        width: 180px;
        height: 75px;
      }
    }
  }
</style>