import paths from '/src/script/config/paths'

const menuConfig = [
    {
        url: paths.home,
        text: 'Accueil'
    },
    {
        url: { name: 'Serie', params: { slug: 'une-riviere-de-larmes-et-d-or' }}, // TODO : paths.books,
        text: 'Romans'
    },
    {
        url: paths.events,
        text: 'Événements',
    },
    {
        url: paths.about,
        text: 'À propos'
    },
    {
        url: paths.contact,
        text: 'Contact'
    },
]

export default menuConfig;