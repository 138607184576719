import devConfig from './dev/app'
import prodConfig from './prod/app'

const env = 'prod'
let webConfig = prodConfig

if (env === 'dev') {
  webConfig = devConfig
}

export default webConfig