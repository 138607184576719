<template>
    <div class="CheckboxField">
        <input v-model="modelValue" :id="id" :name="name" type="checkbox" :disabled="disabled" :required="required" />
        <label v-if='label' class="text-colorPrimary text-bold" :for='id'>{{ label }}</label>
    </div>
</template>

<script>
export default {
    name: 'CheckboxField',
    model: {
        modelValue: 'value',
        event: 'change'
    },
    props: {
        id: String,
        label: String,
        name: { type: String, default: '' },
        value: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false }
    },
    data() {
        return {
            modelValue: this.value
        }
    },
    watch: {
        value: function(newValue) {
            this.modelValue = newValue
        },
        modelValue: function(newValue) {
            this.$emit('change', newValue)
        }
    }
}
</script>